import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme, transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "shared/components/common/base-link";
import { StyledAnchor } from "shared/components/common/base-link/styles";
import { setFullViewportWidthStyles, setResponsiveVariantStyles } from "shared/lib/styles";
import type { ResponsiveVariants } from "shared/lib/styles";

import {
  StyledIconHeadphoneSvg,
  StyledIconSpinnerSvg,
  StyledPlayer,
} from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/button/headphone-icon/styles";
import { MinifyContainer } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/button/styles";
import { Wrapper } from "scmp-app/components/article/article-speech/article-speech-inline-widget/variants/generic/styles";
import { Container as HeaderOnelineMenuStyleContainer } from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/header-oneline-menu-style/styles";
import { DynamicSubscriptionButton } from "scmp-app/lib/sdk/dynamics";

import { DynamicHeaderOnelineMenuContainer } from "./dynamics";
import IconNewsletter from "./header-icon/newsletter.svg";
import IconSearch from "./header-icon/search.svg";
import IconLogoFlag from "./icon-logo-flag.svg";
import IconLogoStyleFlag from "./icon-logo-style-flag.svg";
import IconLogoStyleText from "./icon-logo-style-text.svg";
import IconLogoWithText from "./icon-logo-with-text.svg";

export const StyleHeaderLogoContainer = styled.div`
  margin-inline: auto;
  padding-block: 48px 10px;

  ${theme.breakpoints.up("desktop")} {
    padding-block-start: 64px;
  }

  ${theme.breakpoints.only("mobile")} {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  inline-size: 100%;
  block-size: 45px;
  margin-inline: auto;
  padding-block: 12px;
  box-sizing: border-box;

  background-color: transparent;

  ${theme.breakpoints.up("tablet")} {
    block-size: 58px;
  }

  ${theme.breakpoints.up("desktop")} {
    max-inline-size: 1280px;
  }
`;

export const LeftFeatureContainer = styled.div`
  display: flex;
  align-items: center;

  column-gap: 14px;

  ${theme.breakpoints.up("tablet")} {
    column-gap: 16px;
  }
`;

type ContainerProps = {
  $isMagazineStyle: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${setFullViewportWidthStyles()}
  display: flex;
  flex-flow: column nowrap;
  border-block-end: 1px solid #000000;

  & {
    padding-inline: 20px;

    ${theme.breakpoints.up("tablet")} {
      padding-inline: 32px;
    }

    ${theme.breakpoints.up("desktop")} {
      padding-inline: 80px;
    }

    ${theme.breakpoints.up("mediumDesktop")} {
      display: flex;
      justify-content: center;
    }
  }

  ${props =>
    props.$isMagazineStyle &&
    css`
      padding-inline: 16px 12px;

      ${theme.breakpoints.up("tablet")} {
        padding-inline: 32px;
      }

      ${LeftFeatureContainer} {
        column-gap: 13px;

        ${theme.breakpoints.up("tablet")} {
          column-gap: 24px;
        }
      }

      ${HeaderContainer} {
        ${theme.breakpoints.up("largeDesktop")} {
          inline-size: 1360px;
        }
      }

      ${theme.breakpoints.only("mobile")} {
        border-block-end: none;
      }
    `}
`;

export type IconMenuPlaceholderContainerProps = {
  $responsiveVariants: ResponsiveVariants<"hidden" | "show">;
};

export const IconMenuPlaceholderContainer = styled.div<IconMenuPlaceholderContainerProps>`
  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "hidden":
          return css`
            display: none;

            min-inline-size: 0;
          `;
        case "show":
          return css`
            display: flex;

            min-inline-size: 24px;
          `;
      }
    })}
`;

export const RightFeatureContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;

  column-gap: 16px;

  ${theme.breakpoints.up("tablet")} {
    column-gap: 20px;
  }
`;

export type ActionButtonProps = {
  $responsiveVariants: ResponsiveVariants<"hidden" | "show">;
};
export const ActionButton = styled.div<ActionButtonProps>`
  cursor: pointer;

  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "hidden":
          return css`
            display: none;
          `;
        case "show":
          return css`
            display: flex;
          `;
      }
    })}

  ${StyledAnchor} {
    display: flex;
  }
`;

export const StyledDynamicSubscriptionButton = styled(DynamicSubscriptionButton)``;

export const ArticleSpeechActionButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubscriptionActionButton = styled(ActionButton)`
  & ${StyledDynamicSubscriptionButton} {
    padding: 4px;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.064px;

    ${theme.breakpoints.up("tablet")} {
      padding-block: 7px;
      padding-inline: 8px;

      font-size: 14px;
      line-height: 16px;
      letter-spacing: normal;
    }
  }
`;

export const BookmarkActionButton = styled(ActionButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 24px;
  block-size: 24px;
`;

export const IconMenuContainer = styled.div`
  display: flex;

  path {
    fill: #000000;
  }
`;

export const StyledDynamicHeaderOnelineMenuContainer = styled(DynamicHeaderOnelineMenuContainer)`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: flex;

    margin-inline: 20px;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    ${HeaderOnelineMenuStyleContainer} {
      margin-inline-start: 20px;
    }
  }
`;

export const AvatarContainer = styled.div`
  inline-size: 32px;
  block-size: 32px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 42px;
    block-size: 42px;
  }
`;

export const StyledLogoWithText = styled(IconLogoWithText)`
  inline-size: 207px;
  block-size: 22px;
`;

export const StyledScmpLogoFlag = styled(IconLogoFlag)`
  inline-size: 28.43px;
  block-size: 21.54px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 39px;
    block-size: 30px;
  }
`;

export const StyledStyleLogoContainer = styled.div`
  display: flex;
  align-items: center;

  column-gap: 20px;
`;

export const StyledScmpStyleLogoFlag = styled(IconLogoStyleFlag)`
  inline-size: auto;
  block-size: 20px;

  ${theme.breakpoints.up("tablet")} {
    block-size: 25px;
  }
`;

export const StyledStyleLogoText = styled(IconLogoStyleText)`
  inline-size: auto;
  block-size: 24px;
  margin-block-start: 4px;

  ${theme.breakpoints.up("tablet")} {
    inline-size: 42px;
    block-size: 26.46px;
  }
`;

export const StyledLogoLink = styled(BaseLink, { ...transientOptions })`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export type StyledLogoLinkProps = {
  $shouldSwap: boolean;
};

export const MobileStyledLogoLink = styled(BaseLink, { ...transientOptions })<StyledLogoLinkProps>`
  position: absolute;
  inset-inline-start: 50%;

  display: flex;

  opacity: 1;
  transform: translateX(-50%);

  ${theme.breakpoints.up("tablet")} {
    display: none;
  }

  ${props => css`
    ${props.$shouldSwap &&
    css`
      opacity: 0;

      transition: opacity 0.1s ease-in-out;
    `}
  `}
`;

export const StyledIconSearch = styled(IconSearch)`
  inline-size: 24px;
  block-size: 24px;
`;

export const StyledIconNewsletter = styled(IconNewsletter)`
  inline-size: 24px;
  block-size: 24px;
`;

export const HeaderArticleSpeechContainer = styled.div`
  ${Wrapper} {
    display: flex;
  }

  ${MinifyContainer} {
    display: flex;

    block-size: auto;
  }

  ${StyledIconHeadphoneSvg}, ${StyledIconSpinnerSvg}, ${StyledPlayer} {
    inline-size: 27px;
    block-size: 24px;

    & path {
      fill: #000000;
    }
  }
`;
